import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	InputAdornment,
	MenuItem,
	Skeleton,
	SxProps,
	TextField,
	TextFieldProps,
	Theme,
	Typography,
} from '@mui/material';
import Spacer from 'components/Spacer';
import { Column } from 'components/layout/Column';
import _ from 'lodash';
import React, {
	CSSProperties,
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react';
import { colors } from 'theme';

interface Props extends Omit<TextFieldProps, 'variant'> {
	title?: string;
	caption?: string;
	icon?: IconDefinition;
	inputSx?: SxProps<Theme>;
	multiple?: boolean;
	loading?: boolean;
	variant?: TextFieldProps['variant'];
}

export interface FormTextFieldRef {
	getWidth: () => number;
}

const calculateValue = (props: Props) => {
	const stringValue = props.value as string;
	const arrayValue = props.value as string[];

	if (!props.select) {
		return props.value;
	}

	if (props.multiple) {
		if (arrayValue.length === 0) {
			return ['placeholder'];
		}

		return props.value;
	}

	if (_.isArray(props.value) && props.value.length === 0) {
		return ['placeholder'];
	}

	/**
	 * We check if props.value has trim defined because sometimes
	 * when switching from multiple to single select the value might
	 * be in the wrong format
	 */
	if ((props.value as any)?.trim && !stringValue?.trim()) {
		return 'placeholder';
	}

	return props.value;
};

const FormTextField = forwardRef<FormTextFieldRef, Props>(
	({ title, caption, icon, inputSx, variant = 'filled', ...props }, ref) => {
		const textRef = useRef<HTMLInputElement>(null);

		useImperativeHandle(ref, () => ({
			getWidth: () => textRef.current?.clientWidth ?? 0,
		}));

		const [hasBeenEdited, setHasBeenEdited] = React.useState(false);

		const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setHasBeenEdited(true);

			if (e.target.value.includes('placeholder')) {
				e.target.value = (e.target.value as unknown as string[]).filter(
					(v) => v !== 'placeholder'
				) as unknown as string;
			}

			props.onChange?.(e);
		};

		const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			setHasBeenEdited(true);
			props.onBlur?.(e);
		};

		const handleOnClose = (e: React.SyntheticEvent) => {
			setHasBeenEdited(true);
			props.SelectProps?.onClose?.(e);
		};

		const InputProps = props.InputProps || {};

		if (icon) {
			const iconStyle: CSSProperties =
				variant === 'filled' ? { marginTop: -16 } : {};

			InputProps.startAdornment = (
				<InputAdornment position="start">
					<FontAwesomeIcon icon={icon} style={iconStyle} />
				</InputAdornment>
			);
		}

		const selectSx: SxProps<Theme> = props.select
			? {
					'.MuiFilledInput-root': {
						paddingTop: 0,
						paddingBottom: 0,
					},
					'.MuiSelect-select': {
						paddingTop: 2,
						paddingBottom: 2,
					},
				}
			: {};

		const fullWidthSx: CSSProperties = props.fullWidth
			? {
					width: '100%',
				}
			: {};

		const value =
			typeof props.value === 'string' ? props.value.trim() : props.value;

		const isEmpty =
			!value ||
			(_.isArray(value) && (value.includes('placeholder') || !value.length));

		const hasError =
			props.error || (props.required && isEmpty && hasBeenEdited);

		const helperText =
			props.helperText ?? (props.required ? 'Required' : undefined);

		const calculatedValue = calculateValue(props);

		if (props.select) {
			props.SelectProps = {
				multiple: props.multiple ?? false,
				value: calculatedValue,
			};
		}

		return (
			<Column
				style={{
					...fullWidthSx,
					overflowX: 'clip',
				}}>
				{!!title && <Typography variant="overline">{title}</Typography>}
				{!!caption && (
					<Typography variant="body2" color={colors.secondary} mt={1}>
						{caption}
					</Typography>
				)}

				{props.select && props.loading && (
					<>
						<Skeleton
							variant="rectangular"
							width="100%"
							height={54}
							sx={{
								borderRadius: variant === 'filled' ? '4px 4px 0 0' : '4px',
								mt: 1,
								mb: '3px',
								borderBottom:
									variant === 'filled' ? '1px solid #b0b0b0' : 'none',
							}}
						/>
						{props.required && (
							<Skeleton
								variant="text"
								width={50}
								height={18}
								sx={{ ml: '14px', mb: '5px' }}
							/>
						)}
						<Spacer height={5.25} />
					</>
				)}

				{(!props.select || !props.loading) && (
					<TextField
						{...props}
						ref={textRef}
						variant={variant}
						error={hasError}
						onChange={handleOnChange}
						onBlur={handleOnBlur}
						SelectProps={{
							...props.SelectProps,
							onClose: handleOnClose,
							MenuProps: {
								sx: { maxWidth: 0 },
							},
						}}
						helperText={helperText}
						value={calculatedValue}
						sx={{
							mt: 1,
							mb: 3,
							'.MuiFilledInput-root': {
								paddingTop: 2,
								paddingBottom: 2,
							},
							'.MuiFilledInput-input': {
								paddingTop: 0,
								paddingBottom: 0,
							},
							'.MuiAutocomplete-input': {
								padding: 0,
							},
							'.MuiOutlinedInput-root': {
								paddingTop: '16px !important',
								paddingBottom: '16px !important',
							},
							'.MuiOutlinedInput-input': {
								paddingTop: 0,
								paddingBottom: 0,
							},
							...selectSx,
							...(inputSx ?? {}),
						}}
						InputProps={InputProps}>
						{props.select && props.placeholder && (
							<MenuItem value="placeholder" sx={{ display: 'none' }}>
								{props.placeholder}
							</MenuItem>
						)}
						{props.children}
					</TextField>
				)}
			</Column>
		);
	}
);

export default FormTextField;
