import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import { SpacedRow } from 'components';
import { AccountSettingsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

import PaperRow from '../../common/paper-row';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const subscription = useTypedSelector((state) => state.subscription);
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const handleInspectionReviewAutoapprovalToggle = () => {
		if (!accountSettings) return;

		dispatch(
			AccountSettingsActions.setInspectionReviewAutoapprovalEnabled(
				!accountSettings.is_inspection_review_autoapproval_enabled
			)
		);

		if (!accountSettings.is_inspection_review_autoapproval_enabled) {
			Analytics.trackEvent('auto_approve_inspections_enabled', {
				subscription_id: subscription?.id ?? '',
				chargebee_subscription_id: subscription?.plan?.sub_id ?? '',
			});
		}
	};

	return (
		<PaperRow
			title="Auto-approve inspections shared for review and signature"
			selected={
				accountSettings?.is_inspection_review_autoapproval_enabled ?? false
			}
			handleToggleSection={handleInspectionReviewAutoapprovalToggle}>
			<SpacedRow>
				<Typography color={colors.secondary}>
					When enabled, inspections shared for review and signature ('In-Review'
					status) will be automatically approved once the recipient signs off,
					provided no edits, notes, or changes were made to the original
					inspection.
				</Typography>
				<a
					href="https://help.getrentcheck.com/en/articles/10768977-inspection-workflow-settings"
					style={{ marginLeft: 8, alignSelf: 'flex-end' }}>
					<Typography noWrap sx={{ textDecoration: 'underline' }}>
						Learn More
					</Typography>
				</a>
			</SpacedRow>
		</PaperRow>
	);
};
