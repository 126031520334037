import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Screens } from 'components';
import { SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

import AutoApprove from './auto-approve';
import TwoStepReview from './two-step-review';

import Skeleton from './skeleton';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [loading, setLoading] = useState(!subscription);

	useEffect(() => {
		if (!profile) {
			return;
		}

		if (subscription) {
			setLoading(false);
			return;
		}

		setLoading(true);
		dispatch(SubscriptionActions.getSubscription()).finally(() =>
			setLoading(false)
		);
	}, [profile]);

	if (loading) {
		return <Skeleton />;
	}

	return (
		<Screens.Container>
			<Screens.Header
				title="Inspection Workflow Settings"
				showBreadCrumbs
				subtitle="Tailor how inspections are reviewed and approved in your organization."
			/>

			<AutoApprove />
			<TwoStepReview />
		</Screens.Container>
	);
};
