import { Autocomplete, TextField, Typography } from '@mui/material';

import { Column, OptionMenuItem } from 'components';

import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';
import { useInspectionTemplates } from 'hooks/inspection-templates';
import { useEffect, useState } from 'react';
import { filterOptions, getOptionChipProps, sortTemplates } from './utils';

interface Props {
	/**
	 * Template ids that this field will be populated with on first render
	 * If it contains '*', all templates should be selected
	 */
	initialSelectedIds?: string[];

	/**
	 * Callback that will be called when the selected templates change
	 * @param templateIds will be populated with the selected template ids
	 * If it contains '*', all templates should be selected
	 */
	onChange: (templateIds: string[]) => void;
}

const SelectInspectionTemplate = ({ initialSelectedIds, onChange }: Props) => {
	const { loading, templates } = useInspectionTemplates({
		include_deleted: true,
		include_unavailable: true,
		published_only: false,
		active_only: false,
		sortFunction: sortTemplates,
	});

	const [initialized, setInitialized] = useState(false);
	const [templatesValue, setTemplatesValue] = useState<
		ApiInspectionTemplateDigest[]
	>([]);

	const allTemplatesSelected = templatesValue.length === templates.length;

	useEffect(() => {
		/**
		 * Once we initialize to the default selection we don't want to
		 * re-initialize the component again.
		 */
		if (initialized) {
			return;
		}

		/**
		 * If no templates are present yet then we wait until they are
		 * loaded before initializing the component. Every account should have access to
		 * at least the RC templates so this is fine for all users.
		 */
		if (!templates.length) {
			return;
		}

		setInitialized(true);

		if (initialSelectedIds?.includes('*')) {
			setTemplatesValue(templates);
		} else {
			setTemplatesValue(
				templates.filter((template) =>
					initialSelectedIds?.includes(template.id)
				)
			);
		}
	}, [initialized, loading, templates.length]);

	useEffect(() => {
		/**
		 * We don't want to send change events untils the component is initialized
		 */
		if (!initialized) {
			return;
		}

		onChange(allTemplatesSelected ? ['*'] : templatesValue.map((t) => t.id));
	}, [initialized, templatesValue]);

	const title = 'Select Templates';

	if (loading) {
		return (
			<FormTextField title={title} variant="outlined" loading select required />
		);
	}

	return (
		<Column>
			<Typography variant="overline">{title}</Typography>
			<Autocomplete
				openOnFocus
				fullWidth
				loading={loading}
				clearIcon={null}
				sx={{ mb: 2, mt: 1 }}
				filterOptions={filterOptions}
				ListboxProps={{ style: { maxHeight: 300 } }}
				multiple
				disableCloseOnSelect
				options={templates}
				value={templatesValue}
				onChange={(e, value) => setTemplatesValue(value)}
				getOptionLabel={(option) => option.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				renderTags={(value) => {
					const tagsLimit = 7;
					const tags = value.slice(0, tagsLimit);
					const suffix =
						value.length > tagsLimit ? `+${value.length - tagsLimit} more` : '';

					return (
						<Typography color="#101010" ml={0.5} mr={1}>
							{allTemplatesSelected
								? 'All Templates'
								: `${tags.map((tag) => tag.name).join(', ')} ${suffix}`}
						</Typography>
					);
				}}
				renderOption={(props, option) => (
					<OptionMenuItem
						option={option}
						autocompleteProps={props}
						getOptionLabel={() => option.name}
						getOptionSubLabel={() => option.internal_label}
						getOptionChipProps={getOptionChipProps}
					/>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						helperText="Required"
						error={!templatesValue.length}
						sx={{ '.MuiInputBase-root': { minHeight: 56 }, width: '100%' }}
						placeholder={`Make a Selection`}
					/>
				)}
			/>
		</Column>
	);
};

export default SelectInspectionTemplate;
