import { FilterOptionsState } from '@mui/material';

import { ApiInspectionTemplateDigest } from '@rentcheck/types';

export const getOptionChipProps = (option: ApiInspectionTemplateDigest) => {
	if (option.is_deleted) {
		return {
			label: 'Deleted',
			color: '#FFF',
		};
	}

	if (!option.is_published) {
		return {
			label: 'Unpublished',
			color: '#00000014',
		};
	}

	return {
		label: 'Active',
		color: '#E9F7EC',
	};
};

export const filterOptions = (
	options: ApiInspectionTemplateDigest[],
	state: FilterOptionsState<ApiInspectionTemplateDigest>
) => {
	const searchTerm = state.inputValue.toLowerCase();

	return options.filter((option) =>
		`${option.name} ${option.internal_label} ${getOptionChipProps(option)}`
			.toLowerCase()
			.includes(searchTerm)
	);
};

export const sortTemplates = (
	a: ApiInspectionTemplateDigest,
	b: ApiInspectionTemplateDigest
) => {
	if (a.is_deleted && !b.is_deleted) {
		return 1;
	}

	if (!a.is_deleted && b.is_deleted) {
		return -1;
	}

	if (a.is_published && !b.is_published) {
		return -1;
	}

	if (!a.is_published && b.is_published) {
		return 1;
	}

	return 0;
};
