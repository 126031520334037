import { MenuItem } from '@mui/material';
import { ApiInspection, MaintenanceFlagCustomField } from '@rentcheck/types';
import FormTextField from 'components/form-text-field';
import { useFlagFeatureModalData } from 'hooks/modals';
import { FlagFeatureMetadata } from 'store/reducers/modal-flows';

interface Props {
	value: MaintenanceFlagCustomField[];
	setValue: (value: MaintenanceFlagCustomField[]) => void;
}

const CustomFields = ({ value, setValue }: Props) => {
	const modalData = useFlagFeatureModalData();
	const { inspection } = (modalData?.metadata ?? {}) as FlagFeatureMetadata;

	if (!inspection?.settings.custom_maintenance_fields.length) {
		return null;
	}

	const handleValueChanged = (id: string, newValue: string | string[]) => {
		/**
		 * Since we always save values as string[] we need to make sure that we
		 * save the value as an array, this is necessary because <Select> component
		 * returns a string when multiple = false
		 */
		let effectiveNewValue: string[] = [];

		if (typeof newValue === 'string') {
			effectiveNewValue = [newValue];
		} else {
			effectiveNewValue = newValue;
		}

		/**
		 * If this field was already saved in the maintenance flag, we need to update it.
		 * Otherwise we need to add it to the end of the value array
		 */
		if (value.some((v) => v.id === id)) {
			setValue(
				value.map((v) => (v.id === id ? { ...v, value: effectiveNewValue } : v))
			);
		} else {
			setValue([...value, { id, value: effectiveNewValue }]);
		}
	};

	return (
		<>
			{inspection.settings.custom_maintenance_fields.map((field) => {
				const savedValue = value.find((v) => v.id === field.id)?.value;

				const defaultValue = field.selection_type === 'multiple' ? [] : '';
				const transformedValue =
					(field.selection_type === 'multiple'
						? savedValue
						: savedValue?.[0]) ?? defaultValue;

				return (
					<FormTextField
						key={field.id}
						title={field.title}
						caption={field.description}
						placeholder="Make a Selection"
						value={transformedValue}
						select
						multiple={field.selection_type === 'multiple'}
						onChange={(e) => handleValueChanged(field.id, e.target.value)}>
						{field.options.map((option) => {
							return (
								<MenuItem
									key={option}
									value={option}
									sx={{
										whiteSpace: 'unset',
										minHeight: '40px !important',
										height: 'auto',
									}}>
									{option}
								</MenuItem>
							);
						})}
					</FormTextField>
				);
			})}
		</>
	);
};

export const cleanupCustomFieldResponses = (
	flagFields: MaintenanceFlagCustomField[],
	settings: ApiInspection['settings']['custom_maintenance_fields']
) => {
	return flagFields.map((field) => {
		const settingsForField = settings.find((s) => s.id === field.id);

		if (!settingsForField) {
			return field;
		}

		const cleanValue = field.value.filter((v) =>
			settingsForField.options.includes(v)
		);

		return {
			...field,
			value: cleanValue,
		};
	});
};

export default CustomFields;
