import {
	Autocomplete,
	Card,
	CardContent,
	TextField,
	Typography,
} from '@mui/material';
import { LatchelApi } from '@rentcheck/api-frontend';
import { LatchelProperty, LatchelTenant } from '@rentcheck/api-frontend/';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { colors } from 'theme';
import { formattedInspectionAddress } from 'utils/helpers';

import { APIUnit } from '@rentcheck/types';
import { useProperty } from 'hooks/properties';
import VacancyChip from './vacancy-chip';

interface Props {
	value: LatchelProperty | null;
	setValue: (property: LatchelProperty | null) => void;
	tenants: LatchelTenant[];
	loadingTenants: boolean;
}

const buildOptionLabel = (option: LatchelProperty) => {
	return `${option.address1}, ${option.address2 ? `${option.address2}, ` : ''}${
		option.city
	}, ${option.state} ${option.zip}`;
};

const Property = ({ value, setValue, tenants, loadingTenants }: Props) => {
	const dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Latchel');
	const inspection = modalData?.metadata?.inspection;

	const { property } = useProperty<APIUnit>(inspection?.property.id);

	const [loading, setLoading] = useState(false);
	const [allowPropertyEdit, setAllowPropertyEdit] = useState(true);
	const [latchelProperties, setLatchelProperties] = useState<LatchelProperty[]>(
		[]
	);

	useEffect(() => {
		if (!inspection?.team) return;
		if (!property) return;

		setLoading(true);

		/**
		 * If the property is already linked to a Latchel property,
		 * we should fetch it and disable the property selection.
		 */
		if (property.latchel_property_id) {
			LatchelApi.properties
				.get(inspection.team.id, property.latchel_property_id)
				.then((data) => {
					setAllowPropertyEdit(false);
					setLatchelProperties([data]);
					setValue(data);
				})
				.catch((e) => dispatch(SnackbarActions.showError(e)))
				.finally(() => setLoading(false));

			return;
		}

		/**
		 * Fetch all properties from Latchel to allow the user to select one.
		 */
		LatchelApi.properties
			.getAll(inspection.team.id)
			.then((data) => setLatchelProperties(data))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	}, [property]);

	if (!inspection) return null;

	const emptyStateText = loading
		? 'Loading...'
		: 'No properties were found, you can add them from your Latchel account';

	return (
		<Column>
			<Row>
				<Typography variant="h6">Confirm your Latchel Property:</Typography>
				<Spacer width={2} />
				<Typography sx={{ marginTop: 0.4 }} color={colors.gray}>
					(Required)
				</Typography>
			</Row>
			<Spacer height={4} />
			<Card>
				<CardContent>
					<Typography fontWeight={600}>RentCheck Property:</Typography>
					<Spacer height={2} />
					<Typography>{formattedInspectionAddress(inspection)}</Typography>

					<Spacer height={8} />
					<SpacedRow>
						<Typography fontWeight={600}>Latchel Property:</Typography>
						<VacancyChip
							property={value}
							tenants={tenants}
							loadingTenants={loadingTenants}
						/>
					</SpacedRow>
					<Spacer height={2} />
					<Autocomplete
						disabled={!allowPropertyEdit}
						disablePortal
						options={latchelProperties}
						getOptionLabel={buildOptionLabel}
						value={value}
						onChange={(_, newValue) => setValue(newValue)}
						id="textinput-property"
						noOptionsText={emptyStateText}
						ListboxProps={{ style: { maxHeight: 210 } }}
						renderInput={(params) => (
							<TextField variant="filled" label="Select one" {...params} />
						)}
					/>
				</CardContent>
			</Card>
		</Column>
	);
};

export default Property;
