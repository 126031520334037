import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';

import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import { EmptyState } from './empty-state';
import { SelectionList } from './inspection-selection';

interface Props {
	template?: ApiInspectionTemplateDigest;
	hasAvailableTemplates: boolean;
	handleInspectionTemplate: (value: ApiInspectionTemplateDigest) => void;
	search: string;
	setSearch: (value: React.SetStateAction<string>) => void;
}

export const TemplateSelection = ({
	template,
	hasAvailableTemplates,
	handleInspectionTemplate,
	search,
	setSearch,
}: Props) => {
	
	if (!hasAvailableTemplates) {
		return <EmptyState />;
	}

	return (
		<>
			<TextField
				fullWidth
				placeholder="Search"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<FontAwesomeIcon icon={regular('search')} />
						</InputAdornment>
					),
				}}
			/>
			<SelectionList
				template={template}
				onClick={handleInspectionTemplate}
				search={search}
			/>
		</>
	);
};
