"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCustomMaintenanceFieldAvailable = exports.isTwoStepApprovalRequired = exports.requiresApproval = exports.userIsInvitedTeammate = void 0;
const userIsInvitedTeammate = (inspection) => {
    const validRoles = ['invitee-write', 'invitee-readonly'];
    const validAssigneeTypes = ['teammates'];
    if (validRoles.includes(inspection.role) &&
        validAssigneeTypes.includes(inspection.assigned_recipients.type)) {
        return true;
    }
    return false;
};
exports.userIsInvitedTeammate = userIsInvitedTeammate;
const requiresApproval = (inspection) => {
    const approvalRequiredRecipientTypes = [
        'residents',
        'emails',
    ];
    if (approvalRequiredRecipientTypes.includes(inspection.assigned_recipients.type)) {
        return true;
    }
    return false;
};
exports.requiresApproval = requiresApproval;
const isTwoStepApprovalRequired = (inspection, accountSettings) => {
    if (!accountSettings.two_step_inspection_approval.enabled) {
        return false;
    }
    /**
     * If the template_ids array includes a wildcard it means the user
     * selected 'all templates' to require two-step approval
     */
    if (accountSettings.two_step_inspection_approval.template_ids.includes('*')) {
        return true;
    }
    return accountSettings.two_step_inspection_approval.template_ids.includes(inspection.inspection_template.id);
};
exports.isTwoStepApprovalRequired = isTwoStepApprovalRequired;
const isCustomMaintenanceFieldAvailable = (inspection, customMaintenanceField) => {
    /**
     * If the template_ids array includes a wildcard it means the user
     * selected 'all templates' to include this custom maintenance field
     */
    if (customMaintenanceField.template_ids.includes('*')) {
        return true;
    }
    return customMaintenanceField.template_ids.includes(inspection.inspection_template.id);
};
exports.isCustomMaintenanceFieldAvailable = isCustomMaintenanceFieldAvailable;
