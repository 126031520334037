import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import { LoadingButton, SpacedRow } from 'components';
import { AccountSettingsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

import { TwoStepInspectionApprovalSetting } from '@rentcheck/types';
import SelectInspectionTemplate from 'components/select-inspection-template';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import PaperRow from '../../common/paper-row';

export default () => {
	const dispatch = useDispatch<Dispatch>();

	const subscription = useTypedSelector((state) => state.subscription);
	const accountSettings = useTypedSelector((state) => state.accountSettings);

	const [initialized, setInitialized] = useState(false);
	const [toggleValue, setToggleValue] = useState(false);
	const [templateIds, setTemplateIds] = useState<string[]>([]);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!accountSettings) return;
		if (initialized) return;

		setInitialized(true);

		setToggleValue(accountSettings.two_step_inspection_approval.enabled);
		setTemplateIds(accountSettings.two_step_inspection_approval.template_ids);
	}, [accountSettings?.two_step_inspection_approval, initialized]);

	if (!accountSettings) return null;

	const saveSetting = (settingValue: TwoStepInspectionApprovalSetting) => {
		setLoading(true);

		dispatch(AccountSettingsActions.setInspectionTwoStepApproval(settingValue))
			.then(() =>
				dispatch(
					SnackbarActions.showSuccess(
						'Success! Inspection review setting saved.'
					)
				)
			)
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));

		if (settingValue.enabled) {
			Analytics.trackEvent('two_step_review_enabled', {
				subscription_id: subscription?.id ?? '',
				chargebee_subscription_id: subscription?.plan?.sub_id ?? '',
			});
		}
	};

	const handleToggle = () => {
		const newValue = !toggleValue;

		setToggleValue(newValue);

		/**
		 * When the setting is turned off we auto save it since the save button will
		 * no longer be shown
		 */
		if (!newValue) {
			saveSetting({
				...accountSettings.two_step_inspection_approval,
				enabled: false,
			});
		}
	};

	const handleSave = () => {
		saveSetting({
			enabled: toggleValue,
			template_ids: templateIds,
		});
	};

	const hasUnsavedChanges =
		toggleValue !== accountSettings.two_step_inspection_approval.enabled ||
		_.xor(
			templateIds,
			accountSettings.two_step_inspection_approval.template_ids
		).length > 0;

	const saveButtonDisabled = !hasUnsavedChanges || !templateIds.length;

	return (
		<PaperRow
			title="Two-step inspection review"
			selected={toggleValue}
			handleToggleSection={handleToggle}
			loading={loading}>
			<SpacedRow style={{ marginBottom: 16 }}>
				<Typography color={colors.secondary}>
					Enable two-step approval for selected inspection templates. When
					turned on, inspections submitted for review will go through an
					additional stage before final approval. Reviewers must first mark
					inspections as 'Initial Review' complete before they can approve them,
					adding an extra verification step for critical inspections.
				</Typography>
				<a
					href="https://help.getrentcheck.com/en/articles/10768977-inspection-workflow-settings"
					style={{ marginLeft: 8, alignSelf: 'flex-end' }}>
					<Typography noWrap sx={{ textDecoration: 'underline' }}>
						Learn More
					</Typography>
				</a>
			</SpacedRow>

			{toggleValue && (
				<>
					<SelectInspectionTemplate
						initialSelectedIds={templateIds}
						onChange={setTemplateIds}
					/>
					<SpacedRow>
						<div />
						<LoadingButton
							onClick={handleSave}
							loading={loading}
							disabled={saveButtonDisabled}>
							Save
						</LoadingButton>
					</SpacedRow>
				</>
			)}
		</PaperRow>
	);
};
